import { FC, useState } from 'react'
import { StyledFilterSelectList } from '../Style/Style'
import { FormControl, InputLabel, TextField } from '@material-ui/core'
import styled from 'styled-components'

interface FilterRangeInputProps {
  title: string
  minInputLabel?: string
  maxInputLabel?: string
  minInputPlaceholder?: string
  maxInputPlaceholder?: string
  handleApplyFilter: (minValue: number, maxValue: number) => void
  displayInputValueInTitle?: boolean
  minInputMaxLength?: number
  maxInputMaxLength?: number
  defaultMinValue?: number
  defaultMaxValue?: number
}

export const FilterRangeInput: FC<FilterRangeInputProps> = ({
  title,
  minInputLabel = 'Мин. кол-во',
  maxInputLabel = 'Макс. кол-во',
  minInputPlaceholder,
  maxInputPlaceholder,
  handleApplyFilter,
  displayInputValueInTitle,
  minInputMaxLength = 9,
  maxInputMaxLength = 9,
  defaultMinValue = null,
  defaultMaxValue = null,
}) => {
  const [minValue, setMinValue] = useState<number>(defaultMinValue)
  const [maxValue, setMaxValue] = useState<number>(defaultMaxValue)

  const handleMinInputChange = (event) => {
    const { value } = event.target
    if (minInputMaxLength && value.length > minInputMaxLength) return
    const newMinValue = value ? Number(value) : null

    setMinValue(newMinValue)
    handleApplyFilter(newMinValue, maxValue)
  }

  const handleMaxInputChange = (event) => {
    const { value } = event.target
    if (maxInputMaxLength && value.length > maxInputMaxLength) return
    const newMaxValue = value ? Number(value) : null

    setMaxValue(newMaxValue)
    handleApplyFilter(minValue, newMaxValue)
  }

  return (
    <StyledFilterInputFormControl>
      <StyledFilterSelectList
        value
        renderValue={(v) => {
          if (displayInputValueInTitle && (minValue || maxValue)) {
            const min = minValue || 0
            const max = maxValue || 9999
            return <span>{`${title}: ${min} - ${max}`}</span>
          }

          return <span>{title}</span>
        }}
        // display input option at the bottom
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <div>
          <StyledTextFieldContainer
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <InputLabel
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
            >
              {minInputLabel}:
            </InputLabel>
            <TextField
              type='number'
              value={minValue}
              placeholder={minInputPlaceholder}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              inputProps={{ min: 0 }}
              onChange={handleMinInputChange}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <InputLabel
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
            >
              {maxInputLabel}:
            </InputLabel>
            <TextField
              type='number'
              value={maxValue}
              placeholder={maxInputPlaceholder}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              inputProps={{ min: 0 }}
              onChange={handleMaxInputChange}
            />
          </StyledTextFieldContainer>
        </div>
      </StyledFilterSelectList>
    </StyledFilterInputFormControl>
  )
}

export const StyledFilterInputFormControl = styled(FormControl)`
  border-radius: 2px;
  background-color: #b3b3b3;
  width: 300px;
  height: 35px;
  margin-left: 20px;
  & .MuiInput-underline:before,
  & .MuiInput-underline:after {
    all: unset;
    &:focus,
    &:active,
    &:hover {
      all: unset;
    }
  }
`

const StyledTextFieldContainer = styled.div`
  display: flex;
  width: 300px;
  padding: 10px 20px;

  label {
    margin-right: 15px;
  }
`
