import { gql } from "@apollo/client";

export const GET_SCHOOLS = gql`
  query getSchools($params: GetSchoolsParams!) {
    getSchools(params: $params) {
      schools {
        id
        name
        phone
        email
        idShort
        isPersonal
        teachersCount
        pupilsCount
        manager {
          id
          firstName
          lastName
        }
        country {
          id
          nameEN
        }
        district {
          id
          name
        }
        region {
          id
          name
        }
        city {
          id
          name
        }
        admins {
          id
          firstName
          lastName
          phone
          email
          createdDate
        }
        createdBy {
          id
          firstName
          lastName
          phone
          email
        }
        subscriptionStatus
        subscriptionExpiredDate
      }
      total
    }
  }
`;

export const GET_SCHOOL = gql`
  query getSchool($id: String!) {
    getSchool(id: $id) {
      id
      name
      phone
      photo
      email
      idShort
      isPersonal
      managerId
      countryId
      regionId
      districtId
      cityId
      admins {
        id
        firstName
        lastName
        createdDate
      }
      teachers {
        id
        firstName
        lastName
      }
      planUsers {
        teachers {
          plan
          current
          currentPercent
        }
        pupils {
          plan
          current
          currentPercent
        }
        parents {
          plan
          current
          currentPercent
        }
        admins {
          plan
          current
          currentPercent
        }
        all {
          plan
          current
          currentPercent
        }
      }
      subscriptionInfo {
        plan {
          id
          name
        }
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_PUPILS_BY_SCHOOL = gql`
  query getPupilsBySchool($schoolId: String!) {
    getPupilsBySchool(schoolId: $schoolId) {
      users {
        firstName
        lastName
        middleName
      }
      total
    }
  }
`;

export const GET_USERS_FOR_SCHOOL = gql`
  query getUsersForSchool($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        email
      }
      total
    }
  }
`;

export const GET_SCHOOLS_FOR_SELECTOR = gql`
  query getSchoolsForSelector($params: GetSchoolsParams!) {
    getSchools(params: $params) {
      schools {
        id
        name
      }
      total
    }
  }
`;
export const GET_SCHOOL_GRADES = gql`
  query getSchoolGrades {
    getSchoolGrades {
      id
      name
    }
  }
`;

export const GET_SCHOOL_USERS_CSV = gql`
  query getSchoolUsersCSV($schoolId: UUID!) {
    getSchoolUsersCSV(schoolId: $schoolId)
  }
`;

export const GET_VALIDATE_USER_INVITATIONS_LIST = gql`
  query getValidateUserInvitationsList($list: Upload!) {
    validateUserInvitationsList(list: $list) {
      success
      errors {
        line
        errors
      }
    }
  }
`;

export const GET_COURSES_FOR_ADMIN_PANEL = gql`
  query GetCoursesForAdminPanel($schoolId: UUID!) {
    getCoursesForAdminPanel(schoolId: $schoolId) {
      id
      name
      description
      courseIsAvailable
    }
  }
`;

export const GET_SCHOOL_PARTICIPANTS = gql`
  query getSchoolParticipants(
    $schoolId: UUID!
    $searchKey: String
    $sort: SchoolParticipantSortKey
    $limit: Int
    $skip: Int
  ) {
    getSchoolParticipants(
      schoolId: $schoolId
      searchKey: $searchKey
      sort: $sort
      limit: $limit
      skip: $skip
    ) {
      total
      users {
        id
        fullName
        phone
        email
        role {
          role
        }
        country {
          name
        }
      }
    }
  }
`;
export const GET_SCHOOL_CONNECTION_STATISTIC = gql`
  query getSchoolConnectionStatistic(
    $roles: TeacherPupilRoleEnum!
    $to: Date!
    $from: Date!
    $schoolId: UUID!
  ) {
    getSchoolConnectionStatistic(
      roles: $roles
      to: $to
      from: $from
      schoolId: $schoolId
    ) {
      dataChart {
        tag
        value
      }
      dataTotal
    }
  }
`;
