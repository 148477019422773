import { ChangeEvent, useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { defaultMaterialTheme } from "../../shared/Style/Style";
import { StyledSearchBar, SearchBarInput } from "./SearchBar.style";
import { setUrlQueryParams } from "../../../helpers/setUrlQueryParams.helper";
import { useDebounce } from "../../shared/Utils/OtherOperations/useDebounce";

export interface IActiveFilterState {
  searchKey?: string;
}

interface ISearchBarProps {
  label: string;
  activeFilter: IActiveFilterState;
  setActiveFilter(state: IActiveFilterState & Record<string, any>): void;
  minCharsLimit?: number;
  defaultValue?: string;
}

export const SearchBar = ({
  label,
  activeFilter,
  setActiveFilter,
  minCharsLimit = 2,
  defaultValue = "",
}: ISearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState(defaultValue);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    if (debouncedSearchTerm !== activeFilter.searchKey) {
      if (
        debouncedSearchTerm.length >= minCharsLimit ||
        debouncedSearchTerm === ""
      ) {
        setActiveFilter((prevFilters) => ({
          ...prevFilters,
          searchKey: debouncedSearchTerm || null,
        }));
        setUrlQueryParams({ searchKey: debouncedSearchTerm || null });
      }
    }
  }, [
    debouncedSearchTerm,
    minCharsLimit,
    activeFilter.searchKey,
    setActiveFilter,
  ]);

  return (
    <StyledSearchBar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <SearchBarInput
          label={label}
          value={searchTerm}
          onChange={handleChange}
        />
      </ThemeProvider>
    </StyledSearchBar>
  );
};
