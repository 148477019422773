import { FC, useState, useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { StyledFilterSelectFormControl, StyledFilterSelectList } from '../Style/Style'
import { SelectOptionType } from './types'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface FiltersSelectProps {
  fieldsData: SelectOptionType[]
  handleSetFilter: (value: SelectOptionType['value']) => void
  overrideFormControlStyles?: CSSProperties
  isResetSelectorNeeded?: boolean
  defaultValue?: SelectOptionType['value']
}

export const FiltersSelect: FC<FiltersSelectProps> = ({
  fieldsData,
  handleSetFilter,
  overrideFormControlStyles,
  isResetSelectorNeeded = false,
  defaultValue = null,
}) => {
  const [field, setOption] = useState<SelectOptionType['value']>(defaultValue)

  useEffect(() => {
    if (isResetSelectorNeeded) {
      setOption(null)
      handleSetFilter(null)
    }
  }, [fieldsData])

  const handleChange = (event) => {
    const { value } = event.target
    setOption(value as string)
    handleSetFilter(value as string)
  }

  return (
    <StyledFilterSelectFormControl style={overrideFormControlStyles}>
      <StyledFilterSelectList value={field} onChange={handleChange} displayEmpty>
        {fieldsData.map(({ name, value }) => (
          <MenuItem value={value} key={name}>
            {name}
          </MenuItem>
        ))}
      </StyledFilterSelectList>
    </StyledFilterSelectFormControl>
  )
}
