import {
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from "react";
import { FiltersSelect } from "../shared/Selects/FiltersSelect";
import { SchoolsFiltersType } from "./types";
import {
  endOfSubscriptionOptions,
  subscriptionTypesOptions,
} from "./constants";
import styled from "styled-components";
import { FilterRangeInput } from "../shared/Inputs/FilterRangeInput";
import { useDebounce } from "../shared/Utils/OtherOperations/useDebounce";

interface SchoolsFiltersProps {
  setFilterVariables: Dispatch<SetStateAction<SchoolsFiltersType>>;
  filterVariables: SchoolsFiltersType;
}

export const SchoolsFilters: FC<SchoolsFiltersProps> = ({
  setFilterVariables,
  filterVariables,
}) => {
  const [localFilterVariables, setLocalFilterVariables] =
    useState<SchoolsFiltersType>(filterVariables);

  const debouncedFilterVariables = useDebounce(localFilterVariables, 1000);

  useEffect(() => {
    setFilterVariables(debouncedFilterVariables);
  }, [debouncedFilterVariables, setFilterVariables]);

  const handlePupilsCountFilter = useCallback(
    (minPupilsCount, maxPupilsCount) => {
      setLocalFilterVariables((prevState) => ({
        ...prevState,
        minPupilsCount,
        maxPupilsCount,
      }));
    },
    []
  );

  const handleTeachersCountFilter = useCallback(
    (minTeachersCount, maxTeachersCount) => {
      setLocalFilterVariables((prevState) => ({
        ...prevState,
        minTeachersCount,
        maxTeachersCount,
      }));
    },
    []
  );

  const handleEndOfSubscriptionFilter = useCallback((value) => {
    setLocalFilterVariables((prevState) => ({
      ...prevState,
      daysRangeUntilLicenseExpiration: value,
    }));
  }, []);

  const handleSubscriptionTypeFilter = useCallback((value) => {
    setLocalFilterVariables((prevState) => ({
      ...prevState,
      subscriptionStatus: value,
    }));
  }, []);

  return (
    <StyledSchoolsFiltersContainer>
      <FiltersSelect
        fieldsData={endOfSubscriptionOptions}
        handleSetFilter={handleEndOfSubscriptionFilter}
        overrideFormControlStyles={{ margin: 0 }}
        defaultValue={filterVariables.daysRangeUntilLicenseExpiration}
      />
      <FiltersSelect
        fieldsData={subscriptionTypesOptions}
        handleSetFilter={handleSubscriptionTypeFilter}
        overrideFormControlStyles={{ width: 220 }}
        defaultValue={filterVariables.subscriptionStatus}
      />
      <FilterRangeInput
        title="Количество учеников"
        minInputPlaceholder="чел"
        maxInputPlaceholder="чел"
        handleApplyFilter={handlePupilsCountFilter}
        displayInputValueInTitle
        defaultMinValue={filterVariables.minPupilsCount}
        defaultMaxValue={filterVariables.maxPupilsCount}
      />
      <FilterRangeInput
        title="Количество учителей"
        minInputPlaceholder="чел"
        maxInputPlaceholder="чел"
        handleApplyFilter={handleTeachersCountFilter}
        displayInputValueInTitle
        defaultMinValue={filterVariables.minTeachersCount}
        defaultMaxValue={filterVariables.maxTeachersCount}
      />
    </StyledSchoolsFiltersContainer>
  );
};

const StyledSchoolsFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;
